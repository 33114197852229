
.messageMe {
    background-color: #6610f2;
    color: #fff;
    border-radius: 10px;
    float: right;
    margin-left: 10px;
    margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
  }
  
  .messageUser {
    background-color: #f8f9fa;
      color: #100;
      border-radius: 10px;
      text-align: left;
      margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        float: left;
  }
  
  .message {
  
  
  }
  
  .typingIndicator {
    display: inline-block;
    overflow: hidden;
    width: 1em;
    white-space: nowrap;
  }
  
  .dot {
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    background-color: #333;
    border-radius: 50%;
    margin-right: 0.2em;
    animation: typingAnimation 1s infinite;
  }
  
  @keyframes typingAnimation {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }