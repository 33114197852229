@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"); */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0%;
}
a {
	text-decoration: none;
	color: inherit;
}
ul {
	list-style-type: none;
}
button {
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
}
input:focus {
	outline: none;
}
body {
	margin: 0;
	position: relative;
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
